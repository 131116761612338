import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomControl } from '../../models/CustomForm';
import { Domains } from '../../models/domain-problems/Domains';
import { AisCustomInputComponent } from '../../components/ais-custom-input/ais-custom-input.component';
import { INPUT_TYPE } from '../../../constants/InputTypes';
import { Player } from '../../models/player/Player';

@Component({
  selector: 'app-create-game-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AisCustomInputComponent
  ],
  templateUrl: './create-game-dialog.component.html',
  styleUrl: './create-game-dialog.component.scss'
})
export class CreateGameDialogComponent implements OnInit {
  @Input() domains: Domains[] = [];
  @Input() players: Player[] = [];

  createGameForm: FormGroup;
  inputTypes = INPUT_TYPE;
  
  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.setDefaultDomainValue();
    this.initForm();
  }

  initForm(): void {
    this.createGameForm = new FormGroup({
     numberOfProblems: new CustomControl({
       value: 5,
       label: 'Number of Problems',
       validation: [
         [Validators.required, 'Number of problems is required.']
       ]
     }),
     totalPoints: new CustomControl({
       value: 1000,
       label: 'Goal Points',
       placeholder: 'First player to reach this points wins the game.',
       validation: [
         [Validators.required, 'Goal points is required.']
       ]
     }),
     timeLimit: new CustomControl({
       value: 0,
       label: 'Time Limits (minutes)',
       placeholder: 'Default is 0 (unlimited)',
       validation: [
         [Validators.required, '']
       ]
     }),
     eloLevel: new CustomControl({
       value: 100,
       label: 'ELO Level',
       validation: [
         [Validators.required, '']
       ]
     }),
     domains: new CustomControl({
       label: 'Domains',
       validation: [
         [Validators.required, '']
       ]
     }),
     onlinePlayers: new CustomControl({
       label: 'Online Players'
     }),
    });
  }

  startGame(): void {
    this.activeModal.close(this.createGameForm.getRawValue());
  }
  
  close(): void {
    this.activeModal.close({});
  }

  setDefaultDomainValue = () => this.domains.map((domain) => domain.name.toLowerCase() === 'algebra' ? domain.selected = true : false);

  get f(): { [key: string]: AbstractControl } {
    return this.createGameForm.controls;
  }

  get timeLimit(): CustomControl {
    return this.f['timeLimit'] as CustomControl;
  }

  get domainControl(): CustomControl {
    return this.f['domains'] as CustomControl;
  }

  get numberOfProblems(): CustomControl {
    return this.f['numberOfProblems'] as CustomControl;
  }

  get totalPoints(): CustomControl {
    return this.f['totalPoints'] as CustomControl;
  }

  get eloLevel(): CustomControl {
    return this.f['eloLevel'] as CustomControl;
  }

  get onlinePlayers(): CustomControl {
    return this.f['onlinePlayers'] as CustomControl;
  }
}
