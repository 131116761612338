import { FormControl, ValidatorFn } from "@angular/forms";
import { INPUT_TYPE } from "../../constants/InputTypes";

export type ValidationInfo = [ValidatorFn | string, string];

export interface CustomControlData {
  value?: any;
  validation?: ValidationInfo[];
  label?: string;
  placeholder?: string;
  helperText?: string;
  helperData?: any;
  maxLength?: number;
  maxValue?: number;
  loading?: boolean;
  hasAuxControl?: boolean;
  visible?: boolean;
  type?: INPUT_TYPE;
}

export class CustomValidation {
  error: string;
  message: string;

  constructor(validationPair: ValidationInfo) {
    this.error = typeof validationPair[0] === 'string' ? validationPair[0] : validationPair[0].name;
    this.message = validationPair[1];
  }
}

export class CustomControl extends FormControl {
  auxControl: FormControl;
  validations: CustomValidation[];
  label: string;
  placeholder: string;
  helperText: string;
  maxLength: number;
  maxValue: number;
  loading: boolean;
  visible: boolean;
  helperData: any;
  type: INPUT_TYPE;

  constructor(controlData: CustomControlData) {
    const validatorsFnOrValidatorsName: (string | ValidatorFn)[] | undefined = controlData.validation?.map((validationPair) => validationPair[0]);
    const validators: ValidatorFn[] = (validatorsFnOrValidatorsName?.filter((validator) => typeof validator !== 'string') as ValidatorFn[]) || [];
    super(controlData.value, validators);

    if (controlData.hasAuxControl) this.auxControl = new FormControl();

    this.validations = controlData.validation?.map((validationPair) => new CustomValidation(validationPair)) || [];
    this.label = controlData.label ?? '';
    this.placeholder = controlData.placeholder ?? '';
    this.helperText = controlData.helperData ?? '';
    this.maxLength = controlData.maxLength ?? 0;
    this.maxValue = controlData.maxValue ?? 0;
    this.loading = controlData.loading ?? false;
    this.visible = controlData.visible ?? true;
    this.helperData = controlData.helperData ?? null;
    this.type = controlData.type ?? INPUT_TYPE.TEXT;
  }
}

export function getFunctionWithName(functionObj: ValidatorFn, functionName: string): ValidatorFn {
  return Object.defineProperty(functionObj, 'name', { value: functionName, writable: false });
}
