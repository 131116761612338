import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ReportType } from '../../../constants/ReportType';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomControl } from '../../models/CustomForm';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AisCustomInputComponent } from '../../components/ais-custom-input/ais-custom-input.component';
import { INPUT_TYPE } from '../../../constants/InputTypes';
import ComputePayloadHelper from '../../../helpers/compute-payload.helper';
import { ProblemService } from '../../../services/problem.service';
import { MODAL_RESPONSE } from '../../../constants/Modals';
import { UserService } from '../../../services/user.service';
import { UserFeedbackDto } from '../../models/user-feedbacks/UserFeedbacksDto';

@Component({
  selector: 'app-report-issue-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AisCustomInputComponent
  ],
  templateUrl: './report-issue-dialog.component.html',
  styleUrl: './report-issue-dialog.component.scss'
})
export class ReportIssueDialogComponent implements OnInit {
  @Input() problemId: number;
  @Input() reportType: ReportType = ReportType.ISSUE;
  @Input() title: string = 'Report Issue'

  reportIssueForm: FormGroup;
  reportTypes = ReportType;
  inputTypes = INPUT_TYPE;
  formSubmitted: boolean = false;
  loading: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private problemService: ProblemService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.reportIssueForm = new FormGroup({
      issueDescription: new CustomControl({
        label: `Tell us more about your ${this.reportType === this.reportTypes.ISSUE ? 'issue' : 'feedback'} *`,
        validation: [
          [Validators.required, 'Please describe your issue or feedback.']
        ]
      })
    });
  }

  sendReport(): void {
    this.formSubmitted = true;
    this.loading = true;

    if (this.reportIssueForm.valid) {
      if (this.reportType === ReportType.ISSUE) {
        this.sendIssue();
      } else {
        this.sendFeedback();
      }
    }
  }

  sendIssue(): void {
    const payload = ComputePayloadHelper.reportProblemIssue(this.reportIssueForm.getRawValue(), this.problemId);
    this.problemService.reportProblemIssue(payload).subscribe({
      next: () => {
        this.modal.close({ closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS })
      },
      error: () => {
        this.modal.close({ closeStatus: MODAL_RESPONSE.CLOSE_ERROR })
      }
    });
  }

  sendFeedback(): void {
    const payload = ComputePayloadHelper.reportFeedback(this.reportIssueForm.getRawValue());
    this.userService.addUserFeedback(payload).subscribe({
      next: (data: UserFeedbackDto) => {
        this.modal.close({ closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS, data })
      },
      error: () => {
        this.modal.close({ closeStatus: MODAL_RESPONSE.CLOSE_ERROR })
      }
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.reportIssueForm.controls;
  }

  get issueDescription(): CustomControl {
    return this.f['issueDescription'] as CustomControl;
  }
}
