import { Type } from "@angular/core"
import { CreateGameDialogComponent } from "../shared/dialogs/create-game-dialog/create-game-dialog.component";
import { WarningDialogComponent } from "../shared/dialogs/warning-dialog/warning-dialog.component";
import { ReportIssueDialogComponent } from "../shared/dialogs/report-issue-dialog/report-issue-dialog.component";
import { ImageCropperDialogComponent } from "../shared/dialogs/image-cropper-dialog/image-cropper-dialog.component";
import { InformationDialogComponent } from "../shared/dialogs/information-dialog/information-dialog.component";

export enum MODAL_RESPONSE {
  CLOSE = 'close',
  CLOSE_ALL = 'closeAll',
  CLOSE_SUCCESS = 'closeSuccess',
  CLOSE_ERROR = 'closeError'
}

export const MODALS: { [name: string]: Type<any> } = {
  reportedIssue: ReportIssueDialogComponent,
  createGameDialog: CreateGameDialogComponent,
  warningDialog: WarningDialogComponent,
  imageCropperDialog: ImageCropperDialogComponent,
  information: InformationDialogComponent,
};
