import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, tap } from 'rxjs';
import { UserFeedbackPayload } from '../shared/models/payloads/UserFeedbackPayload';
import { SignalsService } from './signals.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly usersUrl: string = '/v1/user';
  private readonly iamUsersUrl: string = '/v1/iam-user'
  
  constructor(
    private apiService: ApiService,
    private signalService: SignalsService
  ) { }

  getUserDetails(): Observable<any> {
    return this.apiService.get(`${this.usersUrl}/`).pipe(
      tap((user: any) => {
        this.signalService.userDetails.set(user);
      })
    );
  }

  getUserFeedbacks(): Observable<any> {
    return this.apiService.get(`${this.iamUsersUrl}/user-feedback/`)
  }

  addUserFeedback(payload: UserFeedbackPayload): Observable<any> {
    return this.apiService.post(`${this.iamUsersUrl}/user-feedback/`, payload)
  }
}
