<div class="modal-header">
  <h4 class="w-50 modal-title" id="modal-title">{{title}}</h4>
  <div class="w-50 text-end">
    <span class="material-symbols-outlined" style="font-size: 20px; cursor: pointer;" (click)="modal.dismiss('Close')">
      close
    </span>
  </div>
</div>

<div class="modal-body">
  <form [formGroup]="reportIssueForm">
    <div class="col-md-12 mb-3">
      <app-ais-custom-input [control]="issueDescription" [type]="inputTypes.TEXTAREA"></app-ais-custom-input>
      @if (issueDescription.errors) {
        <div class="text-danger mt-2">
          @if (formSubmitted && issueDescription.errors['required']) {
            <span>Please describe your issue or feedback.</span>
          }
        </div>
      }
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-primary ais-primary-btn" [disabled]="!reportIssueForm.valid" (click)="sendReport()">
        <!-- <span class="spinner-border spinner-border-sm mr-1"></span> -->
        Send
      </button>
    </div>
  </form>
</div>
