<div class="modal-header">
  <h4 class="modal-title w-50">Create new game</h4>
  <div class="w-50 text-end">
    <span class="material-symbols-outlined" style="font-size: 20px; cursor: pointer;" (click)="close()">
      close
    </span>
  </div>
</div>

<div class="modal-body">
  <form [formGroup]="createGameForm">
    <div class="col-md-12 mb-3">
      <app-ais-custom-input [control]="numberOfProblems" [type]="inputTypes.SLIDER" [sliderMin]="5" [sliderStep]="5" [sliderMax]="50"></app-ais-custom-input>
    </div>

    <div class="col-md-12 mb-3" style="display: none;">
      <app-ais-custom-input [control]="totalPoints" [type]="inputTypes.SLIDER" [sliderMin]="1000" [sliderStep]="500" [sliderMax]="5000"></app-ais-custom-input>
    </div>

    <div class="col-md-12 mb-3">
      <app-ais-custom-input [control]="timeLimit"></app-ais-custom-input>
    </div>

    <div class="col-md-12 mb-3">
      <app-ais-custom-input [control]="eloLevel" [type]="inputTypes.SLIDER" [sliderMin]="100" [sliderStep]="50" [sliderMax]="1000"></app-ais-custom-input>
    </div>
  
    <div class="col-md-12 mb-3">
      <app-ais-custom-input [control]="domainControl" [type]="inputTypes.DROPDOWN" [listValue]="domains"></app-ais-custom-input>
    </div>

    <div class="col-md-12 mb-3">
      <app-ais-custom-input [control]="onlinePlayers" [type]="inputTypes.DROPDOWN" [listValue]="players"></app-ais-custom-input>
    </div>
  </form>
</div>

<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn ais-primary-btn sign-in-btn" (click)="startGame()">Start Game</button>
  <button type="button" class="btn btn-outline-dark" (click)="close()">Cancel</button>
</div>
