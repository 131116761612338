@switch (type) {
  @case (inputTypes.TEXT) {
    <label class="form-label">{{ control.label }}</label>
    <input type="text" class="form-control input-item" [placeholder]="control.placeholder" [formControl]="control.auxControl ? control.auxControl : control">
  }
  @case (inputTypes.TEXTAREA) {
    <label class="form-label">{{ control.label }}</label>
    <textarea class="form-control" type="text" rows="5" [formControl]="control.auxControl ? control.auxControl : control"></textarea>
  }
  @case (inputTypes.DROPDOWN) {
    <label class="form-label">{{ control.label }}</label>
    <div ngbDropdown class="w-100" >
      <button type="button" class="select-item btn btn-outline-primary w-100 d-flex justify-content-between align-items-center text-truncate" ngbDropdownToggle>
        {{selectedListValueNames}}
      </button>
      <div ngbDropdownMenu class="w-100">
        <mat-checkbox class="w-100" (change)="onDropdownChange($event, 'selectAll')">Select All</mat-checkbox>
        @for (value of listValue; track $index) {
          <mat-checkbox class="w-100" (change)="onDropdownChange($event, value)" [checked]="value.selected">{{value.name ?? 'N/A'}}</mat-checkbox>
        }
      </div>
    </div>
  }
  @case (inputTypes.SLIDER) {
    <label class="form-label d-flex justify-content-between">
      <span>{{control.label}}</span>
      <span>{{control.value}}</span>
    </label>
    @if (control.placeholder) {
      <label class="small" style="color: var(--text-color-accent);">First player to reach this points wins the game.</label>
    }
    <mat-slider class="w-100" [min]="sliderMin" [step]="sliderStep" [max]="sliderMax" style="left: -5px;">
      <input matSliderThumb [formControl]="control.auxControl ? control.auxControl : control">
    </mat-slider>
  }
}
