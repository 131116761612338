import { PlayerGamePayload } from "../shared/models/payloads/PlayerGamePayload";
import { RegisterPayload } from "../shared/models/payloads/RegisterPayload";
import { ReportIssuePayload } from "../shared/models/payloads/ReportIssuePayload";
import { UserFeedbackPayload } from "../shared/models/payloads/UserFeedbackPayload";
import Utils from "./utils.helper";


export default class ComputePayloadHelper {
  
  public static register(form: any, referral_link_code?: string): RegisterPayload {
    let fileToUpload: File | null = null;

    if (form.profilePhoto) {
      fileToUpload = Utils.convertB64ToImage(form.profilePhoto, `${form.userName}}`);
    }
    
    return {
      email: form.email,
      first_name: form.firstName,
      last_name: form.lastName,
      password: form.password,
      password_confirm: form.confirmPassword,
      username: form.userName,
      profile_picture: fileToUpload,
      referral_link_code
    }
  }

  public static startGame(form: any): PlayerGamePayload {
    return {
      cfg_domains: form.domains,
      cfg_elo_level: form.eloLevel,
      cfg_number_of_problems: form.numberOfProblems,
      cfg_time_limit_seconds: form.timeLimit,
      total_points: form.totalPoints,
      players: form.onlinePlayers,
    };
  }

  public static reportProblemIssue(form: any, problemId: number): ReportIssuePayload {
    return {
      description: form.issueDescription,
      problem: problemId
    }
  }

  public static reportFeedback(form: any): UserFeedbackPayload {
    return {
      feedback: form.issueDescription,
    };
  }

}
