import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-dialog',
  standalone: true,
  imports: [],
  templateUrl: './warning-dialog.component.html',
  styleUrl: './warning-dialog.component.scss'
})
export class WarningDialogComponent {
  @Input() title: string = '';
  @Input() description1: string = '';
  @Input() description2: string = '';
  @Input() proceedButtonLabel: string = '';

  constructor(public modal: NgbActiveModal) { }

  proceed(): void {
    this.modal.close({ proceed: true })
  }

  cancel(): void {
    this.modal.close({ proceed: false })
  }
}
