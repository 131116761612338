<div class="modal-header">
  <h4 class="2-50 modal-title" id="modal-title">{{title}}</h4>
  <div class="w-50 text-end">
    <span class="material-symbols-outlined" style="font-size: 20px; cursor: pointer;" (click)="cancel()">
      close
    </span>
  </div>
</div>

<div class="modal-body">
  <p class="mb-0">{{description1}}</p>
  @if (description2) { <p class="mb-0 mt-3">{{description2}}</p> }

  <div class="mb-3 d-flex justify-content-end">
    <button type="button" class="btn btn-primary ais-primary-btn me-2" (click)="proceed()">
      {{proceedButtonLabel}}
    </button>

    <button type="button" class="btn btn-primary ais-secondary-btn" (click)="cancel()">
      {{cancelButtonLabel}}
    </button>
  </div>
</div>
